@charset "UTF-8";
@import url(normalize.css/normalize.css);
@import url("//hello.myfonts.net/count/39e797");
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  src: url("../fonts/swiss-bold.woff2") format("woff2"), url("../fonts/swiss-bold.woff") format("woff");
  font-family: Swiss;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../fonts/swiss-black.woff2") format("woff2"), url("../fonts/swiss-black.woff") format("woff");
  font-family: Swiss;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

[type='text'],
[type='tel'],
[type='email'],
[type='search'],
[type='number'],
[type='url'],
[type='password'],
[type='date'],
textarea {
  width: 100%;
  border: 1px solid currentColor;
  border-radius: 0;
  appearance: none;
}

button,
[type='submit'] {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 1px solid currentColor;
  border-radius: 0;
  appearance: none;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

select {
  position: relative;
  display: block;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

label {
  display: inline-block;
}

label[for] {
  cursor: pointer;
}

[unselectable] {
  user-select: none;
}

[disabled] {
  cursor: not-allowed;
}

h1 {
  font-size: 3.11765rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.23529rem;
}

h4,
h5,
h6 {
  font-size: 0.82353rem;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover, a:active, a:focus {
  text-decoration: underline;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
}

nav ul,
nav ol,
fieldset ul,
fieldset ol {
  list-style: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
iframe,
img,
video {
  max-width: 100%;
  height: auto;
}

:root {
  color: #fff;
  background-color: #1d1d1b;
  box-sizing: border-box;
  font-size: 17px;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  line-height: 1.3;
  text-size-adjust: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #1d1d1b;
  padding-bottom: 2.6rem;
  background-image: url("../images/background.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.fonts-loaded body {
  font-family: Swiss, sans-serif;
}

@media print {
  body * {
    display: none;
  }
  body::after {
    content: 'Please, do not waste paper by printing webpages.';
  }
}

::selection {
  background-color: #fff;
  color: #1d1d1b;
  text-shadow: none;
}

table {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
  vertical-align: top;
}

th {
  text-align: left;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

.font-size--default {
  font-size: 1rem;
}

.font-size--h1 {
  font-size: 3.11765rem;
}

.font-size--h2 {
  font-size: 2rem;
}

.font-size--h3 {
  font-size: 1.23529rem;
}

.font-size--h4 {
  font-size: 0.82353rem;
}

.font-size--order {
  font-size: 1.76471rem;
}

.font--default {
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
}

.fonts-loaded .font--default {
  font-family: Swiss, sans-serif;
}

.font--bold {
  font-family: sans-serif;
  font-weight: 700;
  font-style: normal;
}

.fonts-loaded .font--bold {
  font-family: Swiss, sans-serif;
}

.icon {
  position: relative;
  top: -0.0625em;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.button {
  padding: 0.65rem;
  color: #fff;
  background-color: #1d1d1b;
  border: 1px solid currentColor;
}

.button:hover, .button:active, .button:focus {
  color: #fff;
  text-decoration: none;
}

.button--link {
  display: inline-block;
}

.flow {
  --flow-space: 1em;
}

.flow > :first-child {
  margin-top: 0;
}

.flow > * + * {
  margin-top: 1em;
  margin-top: var(--flow-space);
}

.image {
  margin: 0;
}

.book {
  position: relative;
  display: block;
  padding-top: 50px;
}

@media (min-width: 720px) {
  .book__heading {
    margin-bottom: 3.9rem;
  }
}

.book__subheading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.65rem;
}

@media (min-width: 720px) {
  .book__subheading {
    margin-top: 0;
  }
}

.book__text {
  font-size: 1.23529rem;
  max-width: 900px;
  margin: 0 auto;
}

.footer {
  z-index: 40;
  padding-top: 15.6rem;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  position: relative;
  display: block;
  font-size: 0.82353rem;
}

.footer__list {
  display: flex;
}

@media (min-width: 720px) {
  .footer__list {
    justify-content: flex-end;
  }
}

.footer__item {
  padding-left: 1.3rem;
}

.footer__item:first-child {
  padding: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  will-change: background-color;
  transition: background-color 0.3s ease-in;
  width: 100%;
  height: 50px;
}

.header--scrolled {
  background-color: #1d1d1b;
}

.intro {
  margin-bottom: 3.25rem;
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  overflow: hidden;
}

@media (min-width: 960px) {
  .intro {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.site--meta .intro {
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
}

.intro__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.intro__heading.focusable:active, .intro__heading.focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.intro__videowrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 142.2216vh;
  width: calc(var(--vh, 1vh) * 142.2216);
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  overflow: hidden;
}

@media (min-width: 960px) {
  .intro__videowrapper {
    width: 177.777vh;
    width: calc(var(--vh, 1vh) * 100 * 1.77777);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.site--meta .intro__videowrapper {
  width: 100%;
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
}

.intro__videowrapper::after {
  position: relative;
  display: block;
  content: '';
  z-index: 40;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.intro__video {
  position: relative;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

.site--meta .intro__video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: auto;
  width: 100%;
  height: auto;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  top: calc((80vh - 50px - 60vh) / 2 + 50px);
  height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
  min-height: 40px;
  transform-origin: center bottom;
}

@media (min-width: 960px) {
  .logo {
    top: calc((100vh - 50px - 60vh) / 2 + 50px);
    height: 60vh;
    height: calc(var(--vh, 1vh) * 60);
  }
}

@media (min-width: 720px) {
  .logo--small {
    z-index: 1000;
    position: fixed;
    top: 5px;
    left: 50% !important;
    transform: scale(1) translateX(-50%) !important;
    margin: 0;
    width: auto;
    height: 40px;
  }
}

.logo__image {
  will-change: all;
  transition: all 0.3s ease-in;
  height: 100%;
  max-width: none;
}

.logo__image[aria-hidden='true'] {
  display: none;
  visibility: hidden;
}

.metatext {
  padding-right: 1.3rem;
  padding-left: 1.3rem;
}

@media (min-width: 720px) {
  .metatext {
    padding-right: 2.6rem;
    padding-left: 2.6rem;
  }
}

.nav {
  width: 100%;
  height: 50px;
}

@media (min-width: 960px) {
  .nav {
    width: 40%;
  }
}

.site--scrolled-past-intro .nav {
  background-color: #1d1d1b;
}

.nav__list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (min-width: 720px) {
  .nav__list {
    justify-content: flex-start;
  }
}

.nav__item {
  font-size: 1.23529rem;
  height: 50px;
  line-height: 50px;
  text-align: center;
  flex: 1 1 auto;
}

@media (min-width: 720px) {
  .nav__item:last-child {
    flex: 4 1 auto;
  }
}

@media (min-width: 960px) {
  .nav__item:last-child {
    flex: 1 1 auto;
  }
}

@media (min-width: 960px) {
  .nav__item {
    padding-left: 2.6rem;
  }
}

.nav__link {
  position: relative;
  display: block;
}

.quote {
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  margin-top: 5.2rem;
  font-size: calc(17px + 1vw);
}

@media (min-width: 720px) {
  .quote {
    padding-right: 2.6rem;
    padding-left: 2.6rem;
    font-size: calc(17px + 2vw);
  }
}

@media (min-width: 960px) {
  .quote {
    max-width: 1100px;
  }
}

.quote__paragraph {
  font-weight: 600;
}

.quote__paragraph::before {
  content: '„';
}

.quote__paragraph::after {
  content: '“';
}

.section {
  position: relative;
  display: block;
}

.section__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 90vw;
}

.section__heading {
  font-size: 3.11765rem;
  font-weight: 700;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  line-height: 0.783;
}

@media (min-width: 720px) {
  .section__heading {
    padding-right: 2.6rem;
    padding-left: 2.6rem;
    font-size: calc(53px + 3vw);
  }
}

.shop {
  position: relative;
  display: block;
  padding-top: 50px;
}

@media (min-width: 720px) {
  .shop {
    margin-top: 3.9rem;
  }
}

@media (min-width: 720px) {
  .shop__heading {
    margin-bottom: 3.9rem;
  }
}

.shop__items {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

@media (min-width: 720px) {
  .shop__items {
    padding-right: 1.3rem;
    padding-left: 1.3rem;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.shop__item {
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  margin-bottom: 3.9rem;
}

@media (min-width: 720px) {
  .shop__item {
    padding-right: 1.3rem;
    padding-left: 1.3rem;
    width: 50%;
  }
}

@media (min-width: 960px) {
  .shop__item {
    padding-top: 3.9rem;
  }
}

@media (min-width: 1400px) {
  .shop__item {
    width: 33.3333%;
  }
}

.shop__item-image {
  text-align: center;
}

.shop__item-image > .image__src {
  margin: 0 auto;
}

.shop__item-heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.65rem;
  text-shadow: 0 0 1.3rem rgba(29, 29, 27, 0.8);
}

@media (min-width: 960px) {
  .shop__item-heading {
    margin-top: 0;
  }
}

.shop__item-text {
  text-shadow: 0 0 1.3rem rgba(29, 29, 27, 0.8);
}

.shop__item-order {
  background-color: #fff;
  color: #1d1d1b;
  font-size: 1.76471rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  display: inline-block;
}

@media (min-width: 720px) {
  .shop__item-order {
    font-size: 1.23529rem;
  }
}

.social {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  top: calc(80vh + 1.3rem);
}

@media (min-width: 960px) {
  .social {
    position: fixed;
    top: 0;
    right: 2.6rem;
    left: auto;
    z-index: 40;
    transform: none;
  }
}

.site--meta .social {
  display: none;
  visibility: hidden;
}

.social__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social__item {
  padding-right: 0.65rem;
}

@media (min-width: 960px) {
  .social__item {
    padding-right: 2.6rem;
  }
}

.social__item:last-child {
  padding-right: 0;
}

.social__link {
  position: relative;
  display: block;
  background-color: #fefefe;
  color: #1d1d1b;
  width: 36px;
  height: 36px;
}

@media (min-width: 960px) {
  .social__link {
    color: #fff;
    width: auto;
    height: 50px;
    line-height: 50px;
    background: transparent;
  }
}

.social__label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.social__label.focusable:active, .social__label.focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

@media (min-width: 960px) {
  .social__label {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    color: #fff;
    text-transform: lowercase;
  }
}

.social__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
}

@media (min-width: 960px) {
  .social__icon {
    display: none;
    visibility: hidden;
  }
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.42857%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.story {
  position: relative;
  display: block;
  padding-top: 50px;
}

.story::before {
  padding-top: 70.16667%;
}

.story::before {
  position: relative;
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/spray-5.png");
  transform: translate(-15%, 110%) scale(1.5);
  pointer-events: none;
}

@media (min-width: 720px) {
  .story::before {
    transform: translate(-30%, 85%) scale(1);
  }
}

@media (min-width: 960px) {
  .story::before {
    transform: translate(-30%, 65%) scale(1);
    background-position: center;
  }
}

@media (min-width: 720px) {
  .story__heading {
    margin-bottom: 3.9rem;
  }
}

.story__subheading {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1.3rem;
  margin-bottom: 0.65rem;
}

@media (min-width: 720px) {
  .story__subheading {
    margin-top: 2.6rem;
  }
}

.story__text {
  font-size: 1.23529rem;
  max-width: 900px;
  margin: 0 auto;
}

.story__imageslider {
  margin-top: 2.6rem;
}

.story__imageslider-controls {
  margin-top: 0.65rem;
  outline: 0;
  text-align: center;
  font-size: 200%;
}

.story__imageslider-button {
  padding: 0;
  margin: 0 0.5em;
  border: 0;
  background: transparent;
}

.story__imageslider-icon {
  fill: #fff;
}

/*# sourceMappingURL=main.css.map */